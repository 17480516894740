import * as React from 'react';
import { HashRouter as Router, Route } from 'react-router-dom';
import { FrmIntro } from './forms/FrmIntro';
import { FrmLakeDashboard } from './forms/FrmLakeDashboard';

export default class App extends React.Component {

  render() {
    return (
      <Router>
          <div>
              <Route exact path="/" component={FrmIntro}></Route>
              <Route exact path="/view" component={FrmLakeDashboard}></Route>
          </div>
      </Router>
    );
  }
}
