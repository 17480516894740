import { CircularProgress, ListItem,ListItemAvatar, ListItemText, List, Avatar, Divider } from '@mui/material';
import * as React from 'react';
import { BackendService } from '../lib/BackendService';
import { SessionContext } from '../lib/SessionContext';

export class FrmIntro extends React.Component {

    constructor(props) {
        super(props);
        this.state={
            views: null
        }
    }

    componentDidMount() {
        this.loadViews();
    }

    loadViews() {
        BackendService.call("core/viewConfigurations").then((data) => {
            let res=JSON.parse(data);
            this.setState({
                views: res.ViewConfigurations.Entities
            })
        });
    }

    showView(vc) {
        SessionContext.set({
            viewConfiguration: vc
        });
        window.location.hash="/view";
    }

    render() {
        if(this.state.views==null) {
            return (
                <div id="frm-intro">
                    <div>
                        <CircularProgress></CircularProgress>
                    </div>
                </div>
            );
        }

        let viewItems=this.state.views.map((vc) =>  {
            return (
                <React.Fragment key={vc.Id}>
                    <ListItem className="clickable" onClick={() => this.showView(vc)}>
                        <ListItemAvatar>
                            <Avatar alt={vc.Name} src={vc.Icon} />
                        </ListItemAvatar>
                        <ListItemText
                        primary={vc.Name}
                        secondary={
                            <React.Fragment>
                                {vc.Description}
                            </React.Fragment>
                        }
                        />
                    </ListItem>
                    <Divider></Divider>
                </React.Fragment>
            )
        });

        return (
            <div id="frm-intro">
                <div>
                    <Divider></Divider>
                    <List>{viewItems}</List>
                </div>
            </div>
        )
    }
}