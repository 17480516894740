import * as React from 'react';

class ChartArea extends React.Component {

    render() {
        const { className,title,onSearch,...others } = this.props;

        let cls=className;
        if(!cls)
            cls="chart-area";
        else
            cls="chart-area " + cls;

        return (
            <div className={cls} {...others}>
                { title &&
                    <div className="chart-title">   
                        <div style={{float:'right',marginRight:'8px'}}>
                            { onSearch &&
                                <div className="mif-search clickable" onClick={() => onSearch() }></div>
                            }
                        </div>
                        {this.props.titleTools &&
                            <div style={{float:'right',marginRight:'8px'}}>
                                {this.props.titleTools}
                            </div>
                        }
                        {title}                    
                    </div>
                }
                <div className="chart">
                    {this.props.children}
                </div>
            </div>
        );
    }
}

export default ChartArea;