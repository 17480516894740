import Formats from "../../lib/Formats";

let keyLabels={
    year: "Year",
    month: "Month",
    cyear: "Check-in Year",
    cmonth: "Check-in Month",
    YearMonth: "Year and month",
    TourOperator: "Tour operator",
    Hotel: "Hotel",
    SellChannel: "Sell channel",
    AreaHotel: "Hotel area",
    AreaCustomer: "Customer area"
}

let keyFields={
    cyear: "CheckInYear",
    cmonth: "CheckInMonth"
}

class Charts {

    static bar(key,categories,onClick) {
        return {
            options: {
                chart: {
                    id: key,
                    animations: {
                        speed: 400
                    },
                    events:{
                        dataPointSelection: (evt,chartContext,config) => {
                            onClick(config);
                        }
                    },
                    stacked: true
                },
                xaxis: {
                    categories: categories,
                },
                yaxis: {
                    labels: {
                        formatter: function (value) {
                            return Formats.inChart(value);
                        }
                    },
                },
                dataLabels: {
                    formatter: function(value, opt) {
                        return Formats.withMagnitude(value,1);
                    }
                }
            },
            series: [
                { name: "Value", data: [] }
            ]
        };
    }

    static donut(name,onClick) {
        return {
            options: {
                chart: {
                    id: name,
                    animations: {
                        speed: 400
                    },
                    toolbar: {
                        show: true
                    },
                    events:{
                        dataPointSelection: (evt,chartContext,config) => {
                            onClick(config);
                        }
                    }
                },
                dataLabels: {
                    formatter: function(value, opt) {
                        return Formats.withMagnitude(value,1);
                    }
                },
                tooltip: {
                    y: {
                        formatter: function(value, opt) {
                            return Formats.inChart(value);
                        }
                    }
                },
                labels: [],
                legend: {
                    position: 'bottom'
                }                            
            },                        
            series: []                        
        }
    }

    static keyToLabel(key) {
        if(!keyLabels[key]) return key;
        return keyLabels[key];
    }

    static keyToField(key) {
        if(!keyFields[key]) return key;
        return keyFields[key];
    }
}

export default Charts;