
class UI {

    static showWaitingCursor() {
        document.getElementsByTagName("html")[0].classList.add("wait");
    }

    static hideWaitingCursor() {
        document.getElementsByTagName("html")[0].classList.remove("wait");
    }

}

export default UI;