import { CircularProgress } from '@mui/material';
import * as React from 'react';

export default class FrmLakeDashboard extends React.Component {

    render()  {
        let msg=this.props.message;
        if(!msg) msg="Operation in progress...";
        return (
            <div className="loading">
                <CircularProgress/>
                <div>
                    {msg}
                </div>
            </div>
        )
    }

}