import $ from 'jquery';


class Excel {
    static exportTableToExcel(tableID, filename = '') {
        $('html').addClass("wait");
        setTimeout(() => {
            var downloadLink;
            var dataType = 'application/vnd.ms-excel';
            var tableSelect = document.getElementById(tableID);

            // Copia la tabella e la riformatta
            let tableHtml = $(tableSelect).html();
            $('#raw-export').html("");
            let clone=$('#raw-export').append($(tableHtml));

            // Toglie le parti da rimuovere nell'export
            $.each(clone.find('.hide-in-export'),(i,self) => {
                $(self).remove();
            });

            // Trasforma la presentazione dei valori in dati
            $.each(clone.find('.numeric'),(i,self) => {
                let presentation=$(self).html();
                let val=presentation
                    .replace(/€/g,"")
                    .replace(/\./g,"")
                    .replace(/&nbsp;/g,"");
                $(self).attr("data-presentation",presentation);
                $(self).html(val);
            });

            tableHtml = clone[0].outerHTML.replace(/ /g, '%20');
            
            // Specify file name
            filename = filename?filename+'.xls':'excel_data.xls';
            
            if(navigator.msSaveOrOpenBlob) {
                var blob = new Blob(['\ufeff', tableHtml], {
                    type: dataType
                });
                navigator.msSaveOrOpenBlob( blob, filename);
            } else {
                // Create download link element
                downloadLink = document.createElement("a");
                document.body.appendChild(downloadLink);

                // Create a link to the file
                downloadLink.href = 'data:' + dataType + ', ' + tableHtml;
            
                // Setting the file name
                downloadLink.download = filename;
                
                //triggering the function
                downloadLink.click();
            }
            $('html').removeClass("wait");
        });
    }
}

export default Excel;