import Charts from '../../components/analysis/Charts';
import { LakeDashboard } from '../../components/analysis/LakeDashboard';
import ChartArea from '../../components/custom/ChartArea';
import Chart from "react-apexcharts";
import { BackendService } from '../../lib/BackendService';
import Formats from '../../lib/Formats';
import Loading from '../../components/custom/Loading';
import { MenuItem, Select } from '@mui/material';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';

export class DBSellAnalysis extends LakeDashboard {

    static dashboardTitle() {
        return "Sell analysis";
    }

    constructor(props) {
        super(props);

        this.YEARS=2;
        this.TOPS=10;
        this.mvalues=[1,2,3,4,5,6,7,8,9,10,11,12];
        this.mlabels=Formats.monthLabels();

        this.topSellersSortings=[
            { value: "Sell desc", label: "Sort: Sold (top)", field: "Sell", isCurrency: true },
            { value: "Quantity desc", label: "Sort: Quantity (top)", field: "Quantity" },
            { value: "Sell asc", label: "Sort: Sold (bottom)", field: "Sell", isCurrency: true },
            { value: "Quantity asc", label: "Sort: Quantity (bottom)", field: "Quantity" }
        ];

        let af=props.analysisField;
        if(!af) af="Sell";

        Object.assign(this.state,{
            topSellers: null,
            filtersCollapsed: true,
            enableBottomFilter: true,
            analysisField: af,
            topSellersSorting: this.topSellersSortings[0].value
        });
    }

    setup() {
        console.log("db state",this.state);
        if(!this.state.dashboardConfiguration.FilterCharts) {
            this.state.dashboardConfiguration.FilterCharts=["TourOperator","AreaHotel","SellChannel","AreaCustomer"];
        }

        // Setup dashboard
        let db={
            charts: {
                year: Charts.donut("year",(c) => this.clickOnChart(c)),
                month: Charts.bar("month",this.mvalues,(c) => this.clickOnChart(c)),
                cyear: Charts.donut("cyear",(c) => this.clickOnChart(c)),
                cmonth: Charts.bar("cmonth",this.mvalues,(c) => this.clickOnChart(c))
            }
        };
        let mlbls=this.mlabels;
        db.charts.month.options.xaxis.labels={
            formatter: function (value) {
                return mlbls[parseInt(value)-1];
            }
        };
        db.charts.cmonth.options.xaxis.labels={
            formatter: function (value) {
                return mlbls[parseInt(value)-1];
            }
        };
        for(let fc of this.state.dashboardConfiguration.FilterCharts) {
            console.log("Configuring filter chart '" + fc + "'");
            db.charts[fc]=Charts.donut(fc,(c) => this.clickOnChart(c));
        }

        this.setState({ 
            dashboard: db
        });
    }
    
   loadData() {
        let fields=["Year","Month","CheckInYear","CheckInMonth"];
        for(let fc of this.state.dashboardConfiguration.FilterCharts) {
            fields.push(Charts.keyToField(fc));
        }

        let pars={
            FromYear: new Date().getFullYear()-this.YEARS,
            Fields: fields.join(',')
        }
        BackendService.call("analysis/sellSummary",pars).then((s) => {
            let c=JSON.parse(s);
            console.log("raw data",c);
            this.dataLoaded(c.Sells.Entities);
            this.loadTopSellers();
        });
    }

    prepareDimensionsAndGroups() {          
        this.state.crossfilter.createBasicDimension("year","Year",true);
        this.state.crossfilter.createBasicDimension("month","Month",true);
        this.state.crossfilter.createBasicDimension("cyear","CheckInYear",true);
        this.state.crossfilter.createBasicDimension("cmonth","CheckInMonth",true);
        for(let fc of this.state.dashboardConfiguration.FilterCharts) {
            console.log("Preparing dimension '" + fc + "' on '" + Charts.keyToField(fc) + "'");
            this.state.crossfilter.createBasicDimension(fc,Charts.keyToField(fc),true);
        }

        if(this.state.showBottoms) {
            for(let fc of this.state.dashboardConfiguration.FilterCharts) {
                console.log("Computing top " + this.TOPS + " on '" + fc + "'");
                this.state.crossfilter.computeTops(fc,this.TOPS,this.state.analysisField,true);
            }
        }        
    }

    feedDonut(key) {
        let cf=this.state.crossfilter;
        let data=cf.topValuesToSerie(key,this.TOPS,this.state.analysisField,this.state.dashboard.showBottoms);

        let db=this.state.dashboard;   
        console.log("feedDonut",key,data);
        db.charts[key].series=data.series;
        let opts={}
        if(db.charts[key].options)
            opts=Object.assign({}, db.charts[key].options);
        opts.labels=data.labels;
        db.charts[key].options=opts;
    }

    computeChartData() {      
        let db=this.state.dashboard;   
        let cf=this.state.crossfilter; 
        
        this.feedDonut("year");
        this.feedDonut("cyear");

        let dataSoldPerMonth=[];
        for(let m=1;m<=12;m++)
            dataSoldPerMonth.push(0);
        let reduced=cf.groups.month.reduceSum(i => i[this.state.analysisField]);
        reduced.all().forEach(i => {
            dataSoldPerMonth[i.key-1]=i.value;
        });
        let newSeries=[
            {   
                name: "Value",
                data: dataSoldPerMonth 
            }
        ];
        db.charts.month.series=newSeries;

        let dataSoldPerCMonth=[];
        for(let m=1;m<=12;m++)
            dataSoldPerCMonth.push(0);
        let creduced=cf.groups.cmonth.reduceSum(i => i[this.state.analysisField]);
        creduced.all().forEach(i => {
            dataSoldPerCMonth[i.key-1]=i.value;
        });
        let newCSeries=[
            {   
                name: "Value",
                data: dataSoldPerCMonth 
            }
        ];
        db.charts.cmonth.series=newCSeries;

        for(let fc of this.state.dashboardConfiguration.FilterCharts) {
            console.log("Computing data for filter chart '" + fc + "'");
            this.feedDonut(fc);
        }
    }

    loadTopSellers(sortMode) {
        if(!sortMode) sortMode=this.state.topSellersSorting;
        this.setState({
            topSellers: null,
            selectedArticle: null,
            topSellersSorting: sortMode
        })
        
        let pars={
            FromYear: new Date().getFullYear()-this.YEARS,
            Fields: ["IdHotel","Hotel","AreaHotel"].join(','),
            OrderBy: sortMode
        }

        let activeFilters=[];
        for(var dk in this.state.crossfilter.filters) {
            let fdim=this.state.crossfilter.activeFilters(dk);
            if(fdim) {
                activeFilters.push(Charts.keyToField(dk));
                
                let includes=[];
                let ip=0;
                let ddk=dk;
                fdim.forEach(e => {
                    if(e===this.state.crossfilter.getLabel_Others()) return;
                    let ev=e;
                    pars[Charts.keyToField(ddk) + "_i_" + ip]=ev;
                    includes.push(ev);
                    ip++;
                });

                let im=0;
                fdim.forEach(e => {
                    if(e!==this.state.crossfilter.getLabel_Others()) return;
                    this.state.crossfilter.topRecords[ddk].forEach(s => {
                        if(includes.indexOf(s)>=0) return;
                        pars[Charts.keyToField(ddk) + "_e_" + im]=s;
                        im++;
                    });
                });
            }
        }
        pars["ActiveFilters"]=activeFilters.join(',');

        BackendService.call("analysis/topSellers",pars).then((s) => {
            let c=JSON.parse(s);
            console.log("top sellers",c);
            this.setState({
                topSellers: c.TopSellers.Entities
            })
        });
    }

    filterChanged(key,sel) {        
        this.feedDonut("cyear");
        this.feedDonut("year");
        for(let fc of this.state.dashboardConfiguration.FilterCharts) {
            console.log("Computing data for filter chart '" + fc + "'");
            this.feedDonut(fc);
        }
        this.loadTopSellers();
    }

    getTopSorting() {
        let res=null;
        this.topSellersSortings.forEach(e => {
            if(e.value==this.state.topSellersSorting) res=e;
        });
        return res;
    }

    charts() {
        let topLbl=" (top " + this.TOPS + ")";

        let topSellers=[];
        if(this.state.topSellers) {
            let topField=this.getTopSorting();
            topSellers=this.state.topSellers.map((e,i) => {
                let vv=Formats.quantity(e[topField.field]);
                if(topField.isCurrency) vv=Formats.currency(e[topField.field]);
                return (
                    <tr key={i} className="hotel clickable" onClick={(evt) => this.expandArticle(e,evt)}>
                        <td>
                            <div className="hotel-name">{e.Hotel}</div>
                            <div className="description">{e.AreaHotel}</div>
                        </td>
                        <td>
                            <div className="total">{vv}</div>
                            <div className="markup"></div>
                        </td>
                    </tr>
                );
            });
        }

        let fcharts=[];
        if(this.state.dashboard) {
            for(let fc of this.state.dashboardConfiguration.FilterCharts) {
                fcharts.push(
                    <div key={fc} style={{display:'inline-block',marginRight:'10px'}}>
                        <ChartArea title={"On " + Charts.keyToLabel(fc) + topLbl} 
                                        onSearch={() => this.showFilterSearch({ label: Charts.keyToLabel(fc),value: fc}) }>
                            <Chart 
                                options={this.state.dashboard.charts[fc].options} 
                                series={this.state.dashboard.charts[fc].series}
                                type="donut" width="350"
                                />
                        </ChartArea>
                    </div>
                );
            }
        }

        let tsSortItems=this.topSellersSortings.map((s) => {
            return (
                <MenuItem key={s.value} value={s.value}>{s.label}</MenuItem>
            )
        });

        return (
            <div className="charts-container">
                <div>
                    <ChartArea style={{width:'1140px'}} title="On booking date">
                        <div className="inline-chart">
                            <Chart 
                                options={this.state.dashboard.charts.year.options} 
                                series={this.state.dashboard.charts.year.series}
                                type="donut" width="300" 
                                />
                        </div>
                        <div className="inline-chart">
                            <Chart 
                                options={this.state.dashboard.charts.month.options} 
                                series={this.state.dashboard.charts.month.series}
                                type="bar" width="750" height="200"
                                />
                        </div>
                    </ChartArea>
                    <ChartArea style={{width:'1140px'}} title="On check-in">
                        <div className="inline-chart">
                            <Chart 
                                options={this.state.dashboard.charts.cyear.options} 
                                series={this.state.dashboard.charts.cyear.series}
                                type="donut" width="300" 
                                />
                        </div>
                        <div className="inline-chart">
                            <Chart 
                                options={this.state.dashboard.charts.cmonth.options} 
                                series={this.state.dashboard.charts.cmonth.series}
                                type="bar" width="750" height="200"
                                />
                        </div>
                    </ChartArea>
                    <div style={{verticalAlign:'top',width:'1200px'}}>
                        {fcharts}
                    </div>
                </div>
                <div style={{flexGrow:2}}>
                    <div className="chart-area" style={{width:'95%'}}>
                        <div className="chart-title">
                            {"Top sellers (top 50)"}
                            <div onClick={()=>this.exportTableToExcel('topsellers-table','top_records')} className="btn-2-excel link" style={{float:"right"}}>
                                <TextSnippetIcon />
                            </div>
                        </div>
                        <div>
                            { !this.state.topSellers &&
                                <Loading message="Loading top sellers..." />
                            }
                            { this.state.topSellers &&
                                <div>
                                    <div style={{borderBottom:'1px solid #ddd',paddingBottom:'4px'}}>
                                        <Select size="small" onChange={(e) => this.loadTopSellers(e.target.value)} value={this.state.topSellersSorting} >
                                            {tsSortItems}
                                        </Select>
                                    </div>
                                    <div className="top-sellers articles compact">
                                        <table id="topsellers-table">
                                            <tbody>
                                                {topSellers}
                                            </tbody>
                                        </table>                            
                                    </div>
                                </div>
                            }
                        </div>
                    </div>

                </div>                                     
            </div>
        );
    }

}
