import crossfilter from 'crossfilter2';
import UI from './UI';

class CrossFilters {

    constructor() {
        // oggetto crossfilter sottostante
        this.cf=null;

        // dimensioni per chiave
        this.dims={} 

        // campi da considerare per la dimensione (per chiave dimensione)
        this.dimFields={}

        // gruppi per chiave
        this.groups={} 

        // filtri attivi
        this.filters=[] 

        // se necessario, indicazione (per chiave) dei valori top identificati
        this.topRecords={} 
    }

    getLabel_Others() {
        return "(others)"
    }

    notNullDim(s) {
        if(!s) return "(n.d.)";
        else return s;
    } 

    initFrom(data) {
        this.cf=crossfilter(data);
    }

    createBasicDimension(key,field,alsoDefaultGroup) {
        this.dimFields[key]=field;
        let self=this;
        this.dims[key]=this.cf.dimension(function(d) {
            return self.notNullDim(d[field]);
        });
        if(alsoDefaultGroup) {
            if(this.groups[key]) this.groups[key].dispose();
            this.groups[key]=this.dims[key].group();
        }
    }

    createDimension(key,field,fn,alsoDefaultGroup) {
        this.dimFields[key]=field;
        this.dims[key]=this.cf.dimension(fn);
        if(alsoDefaultGroup) {
            if(this.groups[key]) this.groups[key].dispose();
            this.groups[key]=this.dims[key].group();
        }
    }

    computeTops(key,howMany,reduceField,rebuildGroup) {
        if(!reduceField)
            reduceField="Incasso";
        
        let field=this.dimFields[key];
        let cf=this.cf;
        let topRecords=[];
        this.topRecords[key]=topRecords;

        let reduced=this.groups[key].reduceSum(i => i[reduceField]);
        reduced.top(howMany).forEach(i => {
            let key=this.notNullDim(i.key);
            topRecords.push(key);
        });

        let self=this;
        this.dims[key] = cf.dimension(function(d) {
            let dd=self.notNullDim(d[field]);
            if(self.topRecords[key].indexOf(dd)>=0)
                return dd;
            else 
                return self.getLabel_Others();
        });
        if(rebuildGroup) {
            if(this.groups[key]) this.groups[key].dispose();
                this.groups[key]=this.dims[key].group();
        }
    }

    topValuesToSerie(key,howMany,reduceField,insertOthers) {
        let topTot=0;
        let series=[];
        let lbls=[];
        let reduced=this.groups[key].reduceSum(i => i[reduceField]);
        reduced.top(howMany).forEach((i,idx) => {
            if(Math.abs(i.value)<0.5) return;
            lbls.push(i.key);
            series.push(i.value);
            topTot += i.value;
        });

        if(insertOthers) {
            let granTot=0;
            reduced.all().forEach(i => {                
                granTot += i.value;
            });
            let otherTot=granTot-topTot;
            if(otherTot>0) {
                lbls.push(this.getLabel_Others());
                series.push(otherTot);
            }
        }  
        return { labels: lbls, series: series };      
    }

    toggleFilter(key,filter) {
        return this._internal_toggleFilter(key,filter);
    }

    installFilter(key,filter) {
        if(!this.hasFilter(key,filter))
            return this._internal_toggleFilter(key,filter);
        else 
            return Promise.resolve();     
    }

    removeFilter(key,filter) {
        if(this.hasFilter(key,filter))
            return this._internal_toggleFilter(key,filter);
        else 
            return Promise.resolve();            
    }

    hasFilter(key,filter) {
        let filters=this.filters;
        let coll=filters[key];
        if(!coll) return false;
        else return coll.indexOf(filter)>=0;
    }

    _internal_installFilter(key,filter) {        
        let filters=this.filters;
        let coll=filters[key];
        if(!coll) {
            coll=[];
            coll.push(filter);
            filters[key]=coll;
        } else {
            let idx=coll.indexOf(filter);
            if(idx>=0) {
                coll.splice(idx,1);
                if(coll.length===0) 
                    filters[key]=null;
            } else {
                coll.push(filter);
            }
        }
    }

    _internal_toggleFilter(key,filter) {
        UI.showWaitingCursor();
        let self=this;
        let res=new Promise(function(resolve) {
            setTimeout(() => {
                self._internal_installFilter(key,filter);
    
                let dim=self.dims[key];
                if(!dim) 
                    console.log("Unable to find dimension '" + key + "'")
                else
                    dim.filter(d => self.isInFilters(d,key));
    
                resolve();
                UI.hideWaitingCursor();
            },50);    
        });
        return res;
    }

    isInFilters(d,key) {
        if(!this.filters[key]) return true;
        else return this.filters[key].indexOf(d)>=0;
    }

    isInAllFilters(d,excludedKey) {
        for(var k in this.dims) {
            if(k===excludedKey) continue;
            if(!this.isInFilters(k)) return false;
        }
        return true;
    }

    activeFilters(key) {
        let filters=this.filters;
        let fdim=filters[key];            
        if(fdim && fdim.forEach) {
            let res=[];
            fdim.forEach(i => {
                res.push(i);
            });
            return res;
        }
        else return null;
    }

    dispose() {
        this.cf.dispose();
    }

}

export default CrossFilters;