import Charts from '../../components/analysis/Charts';
import { LakeDashboard } from '../../components/analysis/LakeDashboard';
import ChartArea from '../../components/custom/ChartArea';
import Chart from "react-apexcharts";
import { BackendService } from '../../lib/BackendService';
import Formats from '../../lib/Formats';
import Loading from '../../components/custom/Loading';
import { MenuItem, Select } from '@mui/material';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';

export class DBAvailAnalysis extends LakeDashboard {

    static dashboardTitle() {
        return "Availability coverage";
    }

    constructor(props) {
        super(props);

        this.YEARS=2;
        this.TOPS=10;
        this.mvalues=[1,2,3,4,5,6,7,8,9,10,11,12];
        this.mlabels=Formats.monthLabels();

        this.topAvailableSortings=[
            { value: "Free desc", label: "Sort: Free (top)" },
            { value: "Avail desc", label: "Sort: Availability (top)" },
            { value: "Booked desc", label: "Sort: Booked (top)" },
            { value: "Free asc", label: "Sort: Free (bottom)" },
            { value: "Avail asc", label: "Sort: Availability (bottom)" },
            { value: "Booked asc", label: "Sort: Booked (bottom)" }
        ];

        let af=props.analysisField;
        if(!af) af="Free";

        Object.assign(this.state,{
            topAvailable: null,
            filtersCollapsed: true,
            enableBottomFilter: true,
            analysisField: af,
            topAvailableSorting: this.topAvailableSortings[0].value
        });
    }

    setup() {
        console.log("db state",this.state);
        if(!this.state.dashboardConfiguration.FilterCharts) {
            this.state.dashboardConfiguration.FilterCharts=["AreaHotel","RoomType","Season","ContractType"];
        }

        // Setup dashboard
        let db={
            charts: {
                year: Charts.donut("year",(c) => this.clickOnChart(c)),
                month: Charts.bar("month",this.mvalues,(c) => this.clickOnChart(c)),
                coverage: Charts.donut("coverage",(c) => this.clickOnChart(c))
            }
        };

        let mlbls=this.mlabels;
        db.charts.month.options.xaxis.labels={
            formatter: function (value) {
                return mlbls[parseInt(value)-1];
            }
        };
        for(let fc of this.state.dashboardConfiguration.FilterCharts) {
            console.log("Configuring filter chart '" + fc + "'");
            db.charts[fc]=Charts.donut(fc,(c) => this.clickOnChart(c));
        }

        this.setState({ 
            dashboard: db
        });
    }
    
   loadData() {
        let fields=["Year","Month"];
        for(let fc of this.state.dashboardConfiguration.FilterCharts) {
            fields.push(Charts.keyToField(fc));
        }

        let pars={
            FromYear: new Date().getFullYear(),
            Fields: fields.join(',')
        }
        BackendService.call("analysis/availSummary",pars).then((s) => {
            let c=JSON.parse(s);
            console.log("raw data",c);
            this.dataLoaded(c.Avail.Entities);
            this.loadTopAvailable();
        });
    }

    prepareDimensionsAndGroups() {          
        this.state.crossfilter.createBasicDimension("fakeYear","Year",true); // Fake

        this.state.crossfilter.createBasicDimension("year","Year",true);
        this.state.crossfilter.createBasicDimension("month","Month",true);
        for(let fc of this.state.dashboardConfiguration.FilterCharts) {
            console.log("Preparing dimension '" + fc + "' on '" + Charts.keyToField(fc) + "'");
            this.state.crossfilter.createBasicDimension(fc,Charts.keyToField(fc),true);
        }

        if(this.state.showBottoms) {
            for(let fc of this.state.dashboardConfiguration.FilterCharts) {
                console.log("Computing top " + this.TOPS + " on '" + fc + "'");
                this.state.crossfilter.computeTops(fc,this.TOPS,this.state.analysisField,true);
            }
        }        
    }

    feedDonut(key) {
        let cf=this.state.crossfilter;
        let data=cf.topValuesToSerie(key,this.TOPS,this.state.analysisField,this.state.dashboard.showBottoms);

        let db=this.state.dashboard;   
        console.log("feedDonut",key,data);
        db.charts[key].series=data.series;
        let opts={}
        if(db.charts[key].options)
            opts=Object.assign({}, db.charts[key].options);
        opts.labels=data.labels;
        db.charts[key].options=opts;
    }

    computeCoverage() {
        let db=this.state.dashboard;   
        let cf=this.state.crossfilter; 

        let availTotal=0;
        let availPerYear=cf.groups.fakeYear.reduceSum(i => i["Avail"]);
        availPerYear.all().forEach(i => {
            console.log("- availability " + i.key,i.value);
            availTotal+=i.value;
        });

        let bookedTotal=0;
        let bookedPerYear=cf.groups.fakeYear.reduceSum(i => i["Booked"]);
        bookedPerYear.all().forEach(i => {
            console.log("- booked " + i.key,i.value);
            bookedTotal+=i.value;
        });

        let opts={}
        if(db.charts.coverage.options)
            opts=Object.assign({}, db.charts.coverage.options);
        opts.labels=["Free","Booked"];
        db.charts.coverage.options=opts;
        db.charts.coverage.series=[availTotal-bookedTotal,bookedTotal];
    }

    computeChartData() {      
        let db=this.state.dashboard;   
        let cf=this.state.crossfilter; 
        
        this.feedDonut("year");

        let dataPerMonth=[];
        for(let m=1;m<=12;m++)
            dataPerMonth.push(0);
        let reduced=cf.groups.month.reduceSum(i => i["Free"]);
        reduced.all().forEach(i => {
            dataPerMonth[i.key-1]=i.value;
        });

        let dataPerMonth2=[];
        for(let m=1;m<=12;m++)
            dataPerMonth2.push(0);
        let reduced2=cf.groups.month.reduceSum(i => i["Booked"]);
        reduced2.all().forEach(i => {
            dataPerMonth2[i.key-1]=i.value;
        });

        let newSeries=[
            {   
                name: "Free",
                data: dataPerMonth 
            },
            {   
                name: "Booked",
                data: dataPerMonth2
            }
        ];
        db.charts.month.series=newSeries;

        for(let fc of this.state.dashboardConfiguration.FilterCharts) {
            console.log("Computing data for filter chart '" + fc + "'");
            this.feedDonut(fc);
        }

        this.computeCoverage();
    }

    loadTopAvailable(sortMode) {
        if(!sortMode) sortMode=this.state.topAvailableSorting;
        this.setState({
            topAvailable: null,
            selectedArticle: null,
            topAvailableSorting: sortMode
        })
        
        let pars={
            FromYear: new Date().getFullYear(),
            Fields: ["IdHotel","Hotel","AreaHotel"].join(','),
            OrderBy: sortMode
        }

        let activeFilters=[];
        for(var dk in this.state.crossfilter.filters) {
            let fdim=this.state.crossfilter.activeFilters(dk);
            if(fdim) {
                activeFilters.push(Charts.keyToField(dk));
                
                let includes=[];
                let ip=0;
                let ddk=dk;
                fdim.forEach(e => {
                    if(e===this.state.crossfilter.getLabel_Others()) return;
                    let ev=e;
                    pars[Charts.keyToField(ddk) + "_i_" + ip]=ev;
                    includes.push(ev);
                    ip++;
                });

                let im=0;
                fdim.forEach(e => {
                    if(e!==this.state.crossfilter.getLabel_Others()) return;
                    this.state.crossfilter.topRecords[ddk].forEach(s => {
                        if(includes.indexOf(s)>=0) return;
                        pars[Charts.keyToField(ddk) + "_e_" + im]=s;
                        im++;
                    });
                });
            }
        }
        pars["ActiveFilters"]=activeFilters.join(',');

        BackendService.call("analysis/topAvailable",pars).then((s) => {
            let c=JSON.parse(s);
            console.log("top available",c);
            this.setState({
                topAvailable: c.TopAvailable.Entities
            })
        });
    }

    filterChanged(key,sel) {        
        this.feedDonut("year");
        for(let fc of this.state.dashboardConfiguration.FilterCharts) {
            console.log("Computing data for filter chart '" + fc + "'");
            this.feedDonut(fc);
        }
        this.loadTopAvailable();
    }

    toggleAnalysisField() {
        if(this.state.analysisField==='Booked') {
            this.state.analysisField="Free";
        } else {
            this.state.analysisField="Booked"
        }
        this.computeChartData();
        this.forceUpdate();
    }

    charts() {
        let topLbl=" (top " + this.TOPS + ")";

        let topAvailable=[];
        if(this.state.topAvailable) {
            topAvailable=this.state.topAvailable.map((e,i) => {
                return (
                    <tr key={i} className="hotel clickable" onClick={(evt) => this.expandArticle(e,evt)}>
                        <td>
                            <div className="hotel-name">{e.Hotel}</div>
                            <div className="description">{e.AreaHotel}</div>
                        </td>
                        <td>
                            <div className="total">{Formats.quantity(e["Avail"])}</div>
                            <div className="markup"></div>
                        </td>
                        <td>
                            <div className="total">{Formats.quantity(e["Booked"])}</div>
                            <div className="markup"></div>
                        </td>
                        <td>
                            <div className="total">{Formats.quantity(e["Free"])}</div>
                            <div className="markup"></div>
                        </td>
                    </tr>
                );
            });
        }

        let fcharts=[];
        if(this.state.dashboard) {
            // Inseriamo la percentuale di copertura
            fcharts.push(
                <div key={"coverage"} style={{display:'inline-block',marginRight:'10px'}}>
                    <ChartArea title={"Coverage"} 
                                    onSearch={() => this.showFilterSearch({ label: Charts.keyToLabel("coverage"),value: "coverage"}) }>
                        <Chart 
                            options={this.state.dashboard.charts["coverage"].options} 
                            series={this.state.dashboard.charts["coverage"].series}
                            type="donut" width="350"
                            />
                    </ChartArea>
                </div>
            );
            
            for(let fc of this.state.dashboardConfiguration.FilterCharts) {
                fcharts.push(
                    <div key={fc} style={{display:'inline-block',marginRight:'10px'}}>
                        <ChartArea title={this.state.analysisField + " per " + Charts.keyToLabel(fc) + topLbl} 
                                        onSearch={() => this.showFilterSearch({ label: Charts.keyToLabel(fc),value: fc}) }>
                            <Chart 
                                options={this.state.dashboard.charts[fc].options} 
                                series={this.state.dashboard.charts[fc].series}
                                type="donut" width="350"
                                />
                        </ChartArea>
                    </div>
                );
            }
        }

        let tsSortItems=this.topAvailableSortings.map((s) => {
            return (
                <MenuItem key={s.value} value={s.value}>{s.label}</MenuItem>
            )
        });

        let titleTools=[]
        if(this.state.analysisField=="Free") {
            titleTools.push(
                <div>Showing free rooms, <a className="as-link" onClick={() => this.toggleAnalysisField()}>switch to booked</a></div>
            );
        } else { 
            titleTools.push(
                <div>Showing booked rooms, <a className="as-link" onClick={() => this.toggleAnalysisField()}>switch to free</a></div>
            );
        }

        return (
            <div className="charts-container">
                <div>
                    <ChartArea style={{width:'1140px'}} title="Availability per date" titleTools={titleTools}>
                        <div className="inline-chart">
                            <Chart 
                                options={this.state.dashboard.charts.year.options} 
                                series={this.state.dashboard.charts.year.series}
                                type="donut" width="300" 
                                />
                        </div>
                        <div className="inline-chart">
                            <Chart 
                                options={this.state.dashboard.charts.month.options} 
                                series={this.state.dashboard.charts.month.series}
                                type="bar" width="750" height="200"
                                />
                        </div>
                    </ChartArea>                    
                    <div style={{verticalAlign:'top',width:'1200px'}}>
                        {fcharts}
                    </div>
                </div>
                <div style={{flexGrow:2}}>
                    <div className="chart-area" style={{width:'95%'}}>
                        <div className="chart-title">
                            {"Average values per year (top 50)"}
                            <div onClick={()=>this.exportTableToExcel('topsellers-table','top_records')} className="btn-2-excel link" style={{float:"right"}}>
                                <TextSnippetIcon />
                            </div>
                        </div>
                        <div>
                            { !this.state.topAvailable &&
                                <Loading message="Loading top available..." />
                            }
                            { this.state.topAvailable &&
                                <div>
                                    <div style={{borderBottom:'1px solid #ddd',paddingBottom:'4px'}}>
                                        <Select size="small" onChange={(e) => this.loadTopAvailable(e.target.value)} value={this.state.topAvailableSorting} >
                                            {tsSortItems}
                                        </Select>
                                    </div>
                                    <div className="top-sellers avail-analysis articles compact">
                                        <table id="topsellers-table">
                                            <thead>
                                                <tr>
                                                    <th></th>
                                                    <th>Avail</th>
                                                    <th>Book</th>
                                                    <th>Free</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {topAvailable}
                                            </tbody>
                                        </table>                            
                                    </div>
                                </div>
                            }
                        </div>
                    </div>

                </div>                                     
            </div>
        );
    }

}
