
export class SessionContext {
    static activeSession = null;

    static get() {
        if (SessionContext.activeSession == null) {
            SessionContext.activeSession={
                viewConfiguration: null
            }
        }
        return SessionContext.activeSession;
    }

    static set(obj) {
        let as=SessionContext.get();
        return Object.assign(as,obj);
    }
}