import $ from 'jquery';
import { SessionContext } from './SessionContext';

// Configurazione di sviluppo via node server
let BACKEND_ROOT=(process.env.NODE_ENV === "production") ? ".." : "http://localhost:54149";
let BACKEND_API_ENDPOINT=BACKEND_ROOT + "/Call.aspx";

// Rendiamo accessibile queste informazioni anche a livello di semplice javascript
window.BACKEND_ROOT=BACKEND_ROOT;
window.AUTH_TOKEN=window.location.search.replace("?","");

export class BackendService {

    static backendRoot() {
        return BACKEND_ROOT;
    }

    static backendApiEndpoint() {
        return BACKEND_API_ENDPOINT;
    }

    static getToken() {
        let token=window.location.search.replace("?","");
        return token;
    }

    static async call(operation, parameters) {
        console.log("Call",operation,parameters);

        let token=BackendService.getToken();
        if (!parameters) parameters={}
        parameters["_Op"]=operation;
        parameters["_Token"]=token;
        if(SessionContext.activeSession && SessionContext.activeSession.viewConfiguration) {
            parameters["_View"]=SessionContext.activeSession.viewConfiguration.Id;
        }
        let encodedParams = BackendService.encodeGetParams(parameters);
        let query=BACKEND_API_ENDPOINT + "?" + encodedParams; 
        let req=$.ajax({
            url: query,
            dataType: "text" // sarebbe json, ma viene decodificato dopo
        });

        req.catch((e) => {
            if(e.status) {
                if(e.status===401)  {// Unauthorized
                    alert("Not authorized")
                    return;
                }
            }
            alert("Error");
        })
        return req;
    }

    static encodeGetParams(p) {
        return Object.entries(p)
            .map(kv => {
                // Formatta le date
                if(kv[1] instanceof Date) {
                    let d=kv[1];
                    let datestring = (d.getFullYear() + "-" + ("0"+(d.getMonth()+1)).slice(-2) + "-" + ("0" + d.getDate()).slice(-2))
                         + " " + ("0" + d.getHours()).slice(-2) + ":" + ("0" + d.getMinutes()).slice(-2)  + ":" + ("0" + d.getSeconds()).slice(-2);
                    return [kv[0],datestring];
                } else {
                    return kv;
                }
            })
            /*
            .map(kv => {
                console.log(kv);
                return kv;
            })*/
            .map(kv => kv.map(encodeURIComponent).join("=")).join("&");
    }
}