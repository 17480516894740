import { AppBar, FormControl, IconButton, InputLabel, MenuItem, Select, Toolbar } from '@mui/material';
import * as React from 'react';
import { SessionContext } from '../lib/SessionContext';
import { DBSellAnalysis } from './analysis/DBSellAnalysis';
import { DBWSAnalitics } from './analysis/DBWSAnalitics';
import { DBAvailAnalysis } from './analysis/DBAvailAnalysis';
import { DBWsCacheAnalysis } from './analysis/DBWSCacheAnalysis';

export class FrmLakeDashboard extends React.Component {

    constructor(props) {
        super(props);
        this.dashboards=[
            { label: DBSellAnalysis.dashboardTitle(), value:"DBSellAnalysis", component: <DBSellAnalysis /> },
            { label: DBAvailAnalysis.dashboardTitle(), value:"DBAvailAnalysis", component: <DBAvailAnalysis /> },
            { label: DBWSAnalitics.dashboardTitle(), value:"DBWSAnalitics", component: <DBWSAnalitics /> },
            { label: DBWsCacheAnalysis.dashboardTitle(), value:"DBWSCacheAnalysis", component: <DBWsCacheAnalysis /> }
        ]
        this.state={
            selectedDashboard: null
        }
    }

    componentDidMount() {
        if(!SessionContext.get().viewConfiguration) {
            window.location.hash="/";
        }
    }

    showDashboard(e) {
        let db=null;
        for(let i=0;i<this.dashboards.length;i++) {
            if(this.dashboards[i].value===e.target.value) {
                db=this.dashboards[i];
                break;
            }
        }
        this.setState({
            selectedDashboard: db
        })
    }

    goBack() {
        window.location.hash="/";
    }

    render() {
        if(!SessionContext.get().viewConfiguration) {
            return null;
        }

        let allowedDbs=SessionContext.get().viewConfiguration.Dashboards.split(',');
        let selectItems=[];
        this.dashboards.forEach((dd) => {
            if(!allowedDbs.includes(dd.value)) return;
            selectItems.push(
                <MenuItem key={dd.value} value={dd.value}>{dd.label}</MenuItem>
            );
        });

        return (
            <div id="frm-lake-dashboard">                
                <div>
                    <AppBar position="static" color="default" className="with-outline">
                        <Toolbar>
                            <IconButton size="large" edge="start" color="inherit" onClick={() => this.goBack()}>
                                {/*<Dashboard />*/}
                                <img className="icon" src="icons/dashboard.png" />
                            </IconButton>
                            <FormControl size='small' className="db-selector">
                                <InputLabel id="db-select-label">Dashboard</InputLabel>
                                <Select labelId="db-select-label" placeholder="[No selection]" onChange={(e) => this.showDashboard(e)}>
                                        {selectItems}
                                </Select>
                            </FormControl>
                        </Toolbar>
                    </AppBar>

                    { this.state.selectedDashboard &&
                        <div className="db-container">
                            {this.state.selectedDashboard.component}
                        </div>
                    }
                </div>
            </div>
        )
    }
}