
let currencyFormatter = new Intl.NumberFormat('it-IT', {
    style: 'currency',
    currency: 'EUR',
});
 
let decimalFormatter = new Intl.NumberFormat('it-IT', {
    style: 'decimal'
});
 

class Formats {
    static monthLabels() {
        return ["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"];
    }

    static currency(value) {
       return currencyFormatter.format(value);
    }

    static quantity(value) {
        return decimalFormatter.format(value);
    }

    static withMagnitude(num,digits) {
        const lookup = [
            { value: 1, symbol: "" },
            { value: 1e3, symbol: "k" },
            { value: 1e6, symbol: "M" },
            { value: 1e9, symbol: "G" },
            { value: 1e12, symbol: "T" },
            { value: 1e15, symbol: "P" },
            { value: 1e18, symbol: "E" }
        ];
        const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
        var item = lookup.slice().reverse().find(function(item) {
            return num >= item.value;
        });
        return item ? (num / item.value).toFixed(digits).replace(rx, "$1") + item.symbol : "0";
    }

    static inChart(value) {
        return decimalFormatter.format(Math.round(value));
        //return Formats.withMagnitude(value,1);
    }

    static parseCurrency(value) {
        value="" + value;
        return parseFloat(value.replace(",","."));
     }
 
     static parseQuantity(value) {
        value="" + value;
        return parseFloat(value.replace(",","."));
     }

     static dateFormat(date)
     {
         var d = new Date(date);
         return d.getDate() + "/" + (d.getMonth() + 1) + "/" + d.getFullYear();
     }
}

export default Formats;